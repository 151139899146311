import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import style from './searchbar.module.scss';
interface SearchBarProps extends Omit<HTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
  size?: 'standard' | 'large';
  variant?: 'standard' | 'light';
  value?: string;
  onChange?: (value: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  const {
    placeholder = 'Hitta tjänster...',
    size = 'standard',
    variant = 'standard',
    value,
    className,
    onChange,
    ...rest
  } = props;

  return (
    <div className={classNames(style.wrapper, { [style.large]: size === 'large' }, className)}>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className={classNames(style.search, { [style.light]: variant === 'light' })}
        onChange={(event) => onChange && onChange(event.target.value)}
        {...rest}
      ></input>
    </div>
  );
};
