import { ChangeEvent, HTMLAttributes, useId } from 'react';
import style from './checkbox.module.scss';
import classNames from 'classnames';

interface CheckboxProps extends Omit<HTMLAttributes<HTMLInputElement>, 'type' | 'onChange'> {
  label: string;
  value?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { label, value, id: _id, className, ...rest } = props;
  const randomId = useId();

  const id = _id || `checkbox-${randomId}`;
  return (
    <span className={classNames(style.checkboxWrapper, className)}>
      <input className={className} id={id} value={value} aria-labelledby={`${id}-label`} type="checkbox" {...rest} />
      <label id={`${id}-label`} htmlFor={id}>
        {label}
      </label>
    </span>
  );
};
