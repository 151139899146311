import classNames from 'classnames';
import { HTMLAttributes, useEffect, useRef } from 'react';
import style from './pagination.module.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useOnScreen from '../../utils/use-on-screen.util';
interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
  count: number;
  page: number;
  perPage: number;
  onChangePage: (page: number) => void;
  onChangePerPage?: (pages: number) => void;
  isLoading?: boolean;
}

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { count, page, perPage, onChangePage, onChangePerPage, isLoading, className, ...rest } = props;
  const loadMoreRef = useRef<HTMLButtonElement>(null);
  const loadMoreVisible = useOnScreen(loadMoreRef);
  const pages = [...Array(Math.ceil(count / perPage) || 1)];

  useEffect(() => {
    if (!isLoading) {
      if (page > pages.length) {
        onChangePage(pages.length);
      }
    }
  }, [isLoading, onChangePage, page, pages.length]);

  useEffect(() => {
    if (loadMoreVisible) {
      loadMoreRef.current && loadMoreRef.current.click();
    }
  }, [loadMoreVisible]);

  //TODO: This part works but is not styled. Don't know if it will be used
  const handleChangePerPage = (value: string) => {
    onChangePerPage && onChangePerPage(parseInt(value, 10));
  };

  const shouldRender = (pageNumber: number) => {
    if (page <= 3 && pageNumber <= 5) {
      return true;
    } else if (page >= pages.length - 2 && pageNumber >= pages.length - 4) {
      return true;
    } else if (pageNumber >= page - 2 && pageNumber <= page + 2) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classNames(style.wrapper, className)} {...rest}>
      <label id="pagination-label">
        <span className="bold">
          <span className={style.hidemobile}>{count === 0 ? count : (page - 1) * perPage + 1} – </span>
          {page * perPage <= count ? page * perPage : count}
        </span>{' '}
        av <span className="bold">{count}</span> annonser
      </label>
      <ul
        className={classNames(style.menubar, style.hidemobile)}
        role="menubar"
        aria-label={`Sida ${page} av ${pages.length}`}
      >
        <li>
          <button
            className="outlined gray bullet"
            disabled={page <= 1}
            onClick={() => onChangePage(page - 1)}
            aria-label="Föregående sida"
          >
            <ChevronLeftIcon />
          </button>
        </li>
        <div role="group" className={style.pagegroup}>
          {page > 3 && pages.length > 5 && (
            <>
              <li role="none">
                <button role="menuitem" className={classNames('bullet blue light')} onClick={() => onChangePage(1)}>
                  {1}
                </button>
              </li>
              {page > 4 && pages.length > 6 && (
                <li role="none" className={style.separator}>
                  <span role="separator">...</span>
                </li>
              )}
            </>
          )}
          {pages.map((pageNr, index) => {
            return (
              shouldRender(index + 1) && (
                <li key={`pagi-nr-${index}`} role="none">
                  <button
                    role="menuitem"
                    className={classNames('bullet blue', index + 1 === page ? 'solid' : 'light')}
                    onClick={() => onChangePage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              )
            );
          })}
          {page <= pages.length - 3 && pages.length > 5 && (
            <>
              {page < pages.length - 3 && pages.length > 6 && (
                <li role="none" className={style.separator}>
                  <span role="separator">...</span>
                </li>
              )}
              <li role="none">
                <button
                  role="menuitem"
                  className={classNames('bullet blue light')}
                  onClick={() => onChangePage(pages.length)}
                  aria-label="Nästa sida"
                >
                  {pages.length}
                </button>
              </li>
            </>
          )}
        </div>
        <li>
          <button
            className="outlined gray bullet"
            disabled={page >= pages.length}
            onClick={() => onChangePage(page + 1)}
          >
            <ChevronRightIcon />
          </button>
        </li>
      </ul>
      <div className={style.mobilewrapper}>
        <button
          ref={loadMoreRef}
          className={classNames(style.loadbutton, 'blue', page < pages.length ? 'outlined' : 'light')}
          onClick={() => onChangePage(page + 1)}
          disabled={isLoading || page >= pages.length}
        >
          {isLoading ? 'Laddar fler...' : page < pages.length ? 'Ladda fler...' : 'Inga fler annonser...'}
        </button>
      </div>
      {onChangePerPage && (
        <select onChange={(e) => handleChangePerPage(e.target.value)} value={perPage}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </select>
      )}
    </div>
  );
};
