import { AdType } from 'constants/ad-type.enum';
import style from './ads-filter.module.scss';
import classNames from 'classnames';

interface AdsFilterAdTypeProps {
  value: AdType | undefined;
  onChange: (value: AdType | undefined) => void;
}

export const AdsFilterAdType: React.FC<AdsFilterAdTypeProps> = ({ value, onChange }) => {
  return (
    <fieldset aria-label="Annonstyp" className={classNames(style.row, style.wrap)}>
      <span>
        <input
          checked={!value}
          name="adType"
          type="radio"
          id="adTypeAll"
          value="all"
          onChange={() => onChange(undefined)}
        />
        <label htmlFor="adTypeAll">Alla</label>
      </span>
      <span>
        <input
          checked={value === AdType.Request}
          name="adType"
          type="radio"
          id="adTypeRequests"
          value={AdType.Request}
          onChange={() => onChange(AdType.Request)}
        />
        <label htmlFor="adTypeRequests">Förfrågningar</label>
      </span>
      <span>
        <input
          checked={value === AdType.Service}
          name="adType"
          type="radio"
          id="adTypeServices"
          value={AdType.Service}
          onChange={() => onChange(AdType.Service)}
        />
        <label htmlFor="adTypeServices">Tjänster</label>
      </span>
    </fieldset>
  );
};
