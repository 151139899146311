import { useEffect, useState } from 'react';
import { User, UserFormValues } from '../interfaces/user.interface';
import { apiService } from './api.service';

const handleData = (data: { [key: string]: any }): FormData => {
  const formdata = new FormData();
  Object.keys(data).forEach((key) => {
    if (key !== 'avatar') {
      formdata.append(key, JSON.stringify(data[key]));
    } else {
      formdata.append(key, data[key]);
    }
  });
  return formdata;
};

export const updateUser = (data: Partial<UserFormValues>): Promise<User> => {
  return apiService
    .patch<User>('profile', handleData(data), { headers: { 'Content-type': 'multipart/form-data' } })
    .then((res) => res?.data);
};

export const useUser = (id: string | undefined): { user: User | undefined; loaded: boolean } => {
  const [user, setUser] = useState<User | undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(false);
    apiService.get<User>(`users/${id}`).then((res) => {
      if (res?.data) {
        setUser(res.data);
        setLoaded(true);
      } else {
        setUser(undefined);
      }
    });
  }, [id]);

  return { user, loaded };
};
