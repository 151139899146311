import { FormikValues, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Card } from 'components/card/card.component';
import { Checkbox } from 'components/forms/checkbox/checkbox.component';
import { FormField } from 'components/forms/form-field';
import { MegaRadioButton } from 'components/forms/mega-radio-button/mega-radio-button.component';
import { PriceType } from 'constants/price-type.enum';
import { Column, Grid } from 'components/grid/grid.component';
import { AdType } from 'constants/ad-type.enum';

const AdPrice = () => {
  const [{ value: travelCostCheckboxValue }] = useField('travelCostCheckbox');
  const [{ value: otherCostsCheckboxValue }] = useField('otherCostsCheckbox');
  const [{ value: priceType }] = useField('priceType');
  const [{ value: adType }] = useField('type');

  const { setFieldValue, setTouched } = useFormikContext<FormikValues>();

  const handleContainerClick = (container: PriceType.Fixed | PriceType.Hourly) => {
    setTouched({});

    if (container === PriceType.Fixed) {
      setFieldValue('priceType', PriceType.Fixed);
    }

    if (container === PriceType.Hourly) {
      setFieldValue('priceType', PriceType.Hourly);
    }
  };

  return (
    <Grid>
      <Column span={[6, 6, 12, 4]}>
        <MegaRadioButton
          label="Fast pris"
          name="priceType"
          onChange={() => handleContainerClick(PriceType.Fixed)}
          checked={priceType === PriceType.Fixed}
        >
          <p>
            {adType === AdType.Request
              ? 'Välj detta om du önskar ett fast totalpris.'
              : 'Välj detta om du erbjuder ett fast totalpris.'}
          </p>
          <Grid formgrid>
            <Column span={12}>
              <FormField
                fieldSize="large"
                type="number"
                id="fixedPrice"
                name="fixedPrice"
                label="Pris"
                placeholder="0"
                disabled={priceType !== PriceType.Fixed}
                required
                afterinput="kr"
                min="0"
                max="1000000"
                step="100"
              />
            </Column>
          </Grid>
        </MegaRadioButton>
      </Column>

      <Column span={[6, 6, 12, 4]}>
        <MegaRadioButton
          label="Timpris"
          value="hourly"
          name="priceType"
          checked={priceType === PriceType.Hourly}
          onChange={() => handleContainerClick(PriceType.Hourly)}
        >
          <p>
            {adType === AdType.Request
              ? 'Välj detta om du önskar betala löpande för jobbet.'
              : 'Välj detta om du önskar få löpande betalt för jobbet.'}
          </p>
          <Grid formgrid>
            <Column span={12}>
              <FormField
                fieldSize="large"
                type="number"
                id="hourlyPrice"
                name="hourlyPrice"
                label="Pris"
                placeholder="0"
                disabled={priceType !== PriceType.Hourly}
                required
                afterinput="kr/h"
                min="0"
                max="10000"
                step="50"
              />
            </Column>
          </Grid>
        </MegaRadioButton>
      </Column>

      {adType === AdType.Service && (
        <Column span={[6, 6, 12, 4]} start={[4, 4, 1, 1]}>
          <Card>
            <h2 className="heading-small text-blue-dark">Övriga kostnader</h2>
            <fieldset id="travelCost" aria-labelledby="travelCost-label">
              <legend id="travelCost-label" className="large">
                Reskostnad
              </legend>

              <Checkbox
                id="travelCostCheckbox"
                label="Reskostnader kan tillkomma"
                checked={travelCostCheckboxValue}
                onChange={() => setFieldValue('travelCostCheckbox', !travelCostCheckboxValue)}
              />

              <FormField
                fieldSize="large"
                type="number"
                id="travelCost"
                name="travelCost"
                afterinput="kr/mil"
                aria-labelledby="travelCost-label"
                disabled={!travelCostCheckboxValue} // disable the input if the checkbox is not checked
              />
            </fieldset>

            <fieldset id="otherCosts" aria-labelledby="otherCosts-label">
              <legend id="otherCosts-label" className="large">
                Övriga kostnader
              </legend>

              <Checkbox
                id="otherCostsCheckbox"
                label="Andra konstander kan tillkomma"
                checked={otherCostsCheckboxValue}
                onChange={() => setFieldValue('otherCostsCheckbox', !otherCostsCheckboxValue)}
              />

              <FormField
                fieldSize="large"
                type="text"
                id="otherCosts"
                name="otherCosts"
                placeholder="T.ex. materialkostnader"
                label=""
                disabled={!otherCostsCheckboxValue} // disable the input if the checkbox is not checked
                helpertext="Beskriv de övriga kostnader. T.ex. “materialkostnader”."
              />
            </fieldset>
          </Card>
        </Column>
      )}
    </Grid>
  );
};

AdPrice.title = 'Sätt önskat pris';
AdPrice.label = 'Pris';

AdPrice.initialValues = {
  priceType: PriceType.Fixed,
  fixedPrice: 1000,
  hourlyPrice: 350,
  travelCostCheckbox: false, // Checkbox for Reskostnader
  otherCostsCheckbox: false, // Checkbox for Andra kostnader
  travelCost: '', // Input for Reskostnad
  otherCosts: '', // Input for Övriga kostnader
};

AdPrice.validationSchema = Yup.object().shape({
  priceType: Yup.string().oneOf(Object.values(PriceType)).required('required'),
  fixedPrice: Yup.number().when('priceType', (priceType, schema) => {
    if (priceType[0] === PriceType.Fixed) {
      return schema.required('Du måste fylla i ett pris');
    }
    return schema;
  }),
  hourlyPrice: Yup.number().when('priceType', (priceType, schema) => {
    if (priceType[0] === PriceType.Hourly) {
      return schema.required('Du måste fylla i ett pris');
    }
    return schema;
  }),
  travelCostCheckbox: Yup.boolean(),
  otherCostsCheckbox: Yup.boolean(),
  travelCost: Yup.number().nullable(),
  otherCosts: Yup.string(),
});

export default AdPrice;
