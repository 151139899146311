import { HTMLAttributes } from 'react';
import style from './tag.module.scss';
import classNames from 'classnames';
import ClearIcon from '@mui/icons-material/Clear';

interface TagProps extends HTMLAttributes<HTMLDivElement> {
  color?: 'blue' | 'red' | 'darkblue';
  size?: 'standard' | 'large' | 'small';
  variant?: 'solid' | 'outlined';
  onDelete?: () => void;
  onClick?: () => void;
}

export const Tag: React.FC<TagProps> = (props) => {
  const {
    color = 'blue',
    size = 'standard',
    variant = 'solid',
    className,
    onDelete,
    onClick,
    children,
    ...rest
  } = props;

  const getSize = () => {
    switch (size) {
      case 'large':
        return style.large;
      case 'small':
        return style.small;
      default:
        return;
    }
  };
  const getVariant = () => {
    switch (variant) {
      case 'solid':
        return style.solid;
      case 'outlined':
        return style.outlined;
      default:
        return style.solid;
    }
  };
  const getColor = () => {
    switch (color) {
      case 'blue':
        return style.blue;
      case 'red':
        return style.red;
      case 'darkblue':
        return style.darkblue;
      default:
        return style.blue;
    }
  };

  return (
    <div
      className={classNames(style.tag, getSize(), getVariant(), getColor(), { [style.button]: !!onClick }, className)}
      {...rest}
      onClick={() => {
        onClick && onClick();
      }}
      role={!onClick ? 'none' : 'button'}
      tabIndex={!onClick ? -1 : 0}
      onKeyDown={(e) => {
        e.key === 'Enter' && onClick && onClick();
      }}
    >
      {children}
      {onDelete && <ClearIcon tabIndex={0} role="button" className={style.deletebutton} onClick={() => onDelete()} />}
    </div>
  );
};
