import { SearchBar } from 'components/searchbar/searchbar.component';
import style from './search-header.module.scss';
import { useNavigate } from 'react-router-dom';
import { FormEvent, useState } from 'react';
import { useUserStore } from 'stores';
import { AdType } from 'constants/ad-type.enum';
import theme from 'styles/theme.module.scss';
import { useMediaQuery } from '@react-hookz/web';
import { useAdsStore } from 'services/ads.service';

export const SearchHeader: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const isMobile = useMediaQuery(`screen and (max-width:${theme['breakpoint-s']})`);
  const [setSearchstring, setFilter] = useAdsStore((state) => [state.setSearchstring, state.setFilter]);

  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setFilter({ type: AdType.Service, placeId: user?.placeId ? [user.placeId, 0] : undefined });
    setSearchstring(query);
    navigate('/search');
  };

  return (
    <div className={style.wrapper}>
      <h1 className="text-blue-dark">
        Hitta tjänster för dig,
        <br />i ditt område!
      </h1>
      <form onSubmit={handleSubmit}>
        <SearchBar size={isMobile ? 'standard' : 'large'} onChange={setQuery} />
      </form>
    </div>
  );
};
