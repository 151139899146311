import { Field, FieldProps, FormikValues, useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import startSvipper from 'assets/illustrations/Group 799.png';
import { MegaRadioButton } from 'components/forms/mega-radio-button/mega-radio-button.component';
import { AccountType } from 'constants/account-type.enum';
import { useUserStore } from 'stores';
import style from './account-type.module.scss';
import { Card } from 'components/card/card.component';
import { Column, Grid } from 'components/grid/grid.component';

const SelectAccountType = () => {
  const { user } = useUserStore();
  const { setFieldValue } = useFormikContext<FormikValues>();
  const [field, meta] = useField('type');

  useEffect(() => {
    if (user && !meta.touched) {
      setFieldValue('type', user.type);
    }
    //eslint-disable-next-line
  }, [user]);

  return (
    <Grid>
      <Column span={12}>
        <Card className={style.banner}>
          <div className={style.content}>
            <div className={style.innerContent}>
              <h2 className="heading-large">Vad kul att du vill börja Svippa!</h2>
              <p className="text-large semibold">
                Vi kommer nu att guida dig genom några få steg för att du ska kunna börja skapa annonser.
              </p>
              <p className="text-large semibold">
                Vi börjar med kontotyp. Är du registrerad som företag är det bara att hoppa till Gå vidare direkt.
              </p>
            </div>
          </div>
          <div className={style.imageWrapper}>
            <img className={style.img} alt="" src={startSvipper} />
          </div>
        </Card>
      </Column>
      <Column span={12}>
        <form>
          <Grid>
            <Column span={[6, 6, 12, 4]}>
              <Field name="type">
                {({ field }: FieldProps) => (
                  <MegaRadioButton
                    {...field}
                    disabled={user?.type === AccountType.Company}
                    label="Privatperson"
                    value={AccountType.Private}
                    checked={field.value === AccountType.Private}
                  >
                    <div className={style.radiocontent}>
                      <p className="text-large semibold">
                        {user?.type === AccountType.Company
                          ? 'Du är redan registrerad som företag. Det går ej att göra om ett företagskonto till privatperson.'
                          : 'Om du vill sälja som privatperson går det alldeles utmärkt. Du måste bara svara på några extra frågor.'}
                      </p>
                    </div>
                  </MegaRadioButton>
                )}
              </Field>
            </Column>
            <Column span={[6, 6, 12, 4]}>
              <Field name="type">
                {({ field }: FieldProps) => (
                  <MegaRadioButton
                    {...field}
                    label="Företag"
                    value={AccountType.Company}
                    checked={field.value === AccountType.Company}
                  >
                    <div className={style.radiocontent}>
                      <p className="text-large semibold">
                        {user?.type === AccountType.Company
                          ? 'Som företag kan du snabbt komma igång att sälja. Du behöver bara komplettera med lite information.'
                          : 'Om du har ett registrerat företag hjälper vi dig att omvandla ditt konto till ett företagskonto.'}
                      </p>
                    </div>
                  </MegaRadioButton>
                )}
              </Field>
            </Column>
          </Grid>
        </form>
      </Column>
    </Grid>
  );
};

SelectAccountType.title = 'Bli svippare';
SelectAccountType.initialValues = {
  type: '',
};
SelectAccountType.validationSchema = Yup.object().shape({
  type: Yup.mixed<AccountType>().oneOf(Object.values(AccountType)).required(),
});

export { SelectAccountType };
