import { Tag } from 'components/tag/tag.component';
import { AdTypeLabels } from 'constants/ad-type.enum';
import { AdsFilterOptions } from 'services/ads.service';
import style from './ads-filter.module.scss';
import { useCategories } from 'services/categories.service';
import classNames from 'classnames';
import { useLocations } from 'services/locations.service';
import { PriceType, PriceTypeLabels } from 'constants/price-type.enum';
interface AdsFilterSummaryProps {
  filter: AdsFilterOptions;
  onChange?: (filter: AdsFilterOptions) => void;
}

export const AdsFilterSummary: React.FC<AdsFilterSummaryProps> = ({ filter, onChange }) => {
  const { data: categories } = useCategories();
  const { data: locations } = useLocations();

  const getFiltersText = (): string => {
    const filters: string[] = [];
    if (filter?.type) {
      filters.push(AdTypeLabels[filter.type]);
    }
    if (filter.categories && filter.categories?.length > 0) {
      filter.categories.forEach((catid) => {
        const catlabel = categories?.find((cat) => cat.id === catid)?.label;
        if (catlabel) {
          filters.push(catlabel);
        }
      });
    }
    if (filter.placeId && filter.placeId?.length > 0) {
      filter.placeId.forEach((locid) => {
        const locLabel = locations?.find((loc) => loc.id === locid)?.label;
        if (locLabel) {
          filters.push(locLabel);
        }
      });
    }
    if (filter?.priceType) {
      filters.push(PriceTypeLabels[filter.priceType]);
    }
    if (filter.priceType !== PriceType.Hourly && filter.fixedPriceTo && filter.fixedPriceFrom) {
      filters.push(`${filter.fixedPriceFrom} — ${filter.fixedPriceTo} kr`);
    }
    if (filter.priceType !== PriceType.Fixed && filter.hourlyPriceTo && filter.hourlyPriceFrom) {
      filters.push(`${filter.hourlyPriceFrom} — ${filter.hourlyPriceTo} kr/h`);
    }

    return filters.join(', ');
  };

  return !onChange ? (
    <div className={classNames(style.row, style.wrap)}>
      {getFiltersText() && <span className="text-small">Filtrerar på: {getFiltersText()}</span>}
    </div>
  ) : (
    <div className={classNames(style.row, style.wrap)}>
      {filter?.type && (
        <Tag size="small" variant="outlined" color="darkblue" onDelete={() => onChange({ ...filter, type: undefined })}>
          {AdTypeLabels[filter.type]}
        </Tag>
      )}

      {filter?.categories?.map((catId, index) => {
        const cat = categories.find((cat) => cat.id === catId);
        return (
          cat && (
            <Tag
              size="small"
              variant="outlined"
              color="darkblue"
              key={`tag-cat-${index}-${catId}`}
              onDelete={() => onChange({ ...filter, categories: filter.categories?.filter((cat) => cat !== catId) })}
            >
              {cat.label}
            </Tag>
          )
        );
      })}

      {filter?.placeId?.map((locId, index) => {
        const loc = locations.find((loc) => loc.id === locId);
        return (
          loc && (
            <Tag
              size="small"
              variant="outlined"
              color="darkblue"
              key={`tag-loc-${index}-${locId}`}
              onDelete={() => onChange({ ...filter, placeId: filter.placeId?.filter((loc) => loc !== locId) })}
            >
              {loc.label}
            </Tag>
          )
        );
      })}

      {filter?.priceType && (
        <Tag
          size="small"
          variant="outlined"
          color="darkblue"
          onDelete={() => onChange({ ...filter, priceType: undefined })}
        >
          {PriceTypeLabels[filter.priceType]}
        </Tag>
      )}

      {filter?.priceType !== PriceType.Hourly && filter.fixedPriceTo ? (
        <Tag
          size="small"
          variant="outlined"
          color="darkblue"
          onDelete={() => onChange({ ...filter, fixedPriceFrom: undefined, fixedPriceTo: undefined })}
        >
          {`${filter.fixedPriceFrom} — ${filter.fixedPriceTo} kr`}
        </Tag>
      ) : (
        <></>
      )}

      {filter?.priceType !== PriceType.Fixed && filter.hourlyPriceTo ? (
        <Tag
          size="small"
          variant="outlined"
          color="darkblue"
          onDelete={() => onChange({ ...filter, hourlyPriceFrom: undefined, hourlyPriceTo: undefined })}
        >
          {`${filter.hourlyPriceFrom} — ${filter.hourlyPriceTo} kr/h`}
        </Tag>
      ) : (
        <></>
      )}
    </div>
  );
};
