import { useModalsStore, useUserStore } from '../../../stores';
import { LoginForm } from '../../forms';
import { Modal } from '../modal.component';

import axios from 'axios';
import { FormikHelpers } from 'formik';
import { Tokens } from 'interfaces/tokens.interface';
import { User } from 'interfaces/user.interface';
import { LoginPayload } from '../../../interfaces/login.interface';
import style from './login-modal.module.scss';

interface LoginModalProps {
  onClose?: () => void;
  onSuccess?: (type: string) => void;
}

const LoginModal = (props: LoginModalProps) => {
  const { onClose, onSuccess } = props;

  const open = useModalsStore((state) => state.showLogin);
  const setOpen = useModalsStore((state) => state.setShowLogin);
  const setUser = useUserStore((state) => state.setUser);
  const setTokens = useUserStore((state) => state.setTokens);

  const setShowRegistration = useModalsStore((state) => state.setShowRegistration);

  const handleSubmit = (fields: LoginPayload, helpers: FormikHelpers<LoginPayload>) => {
    const { setFieldError } = helpers;

    // TODO: Get URL from a environment variable and move REST API communication to a API service or something
    axios
      .post<{ user?: User; tokens: Tokens; message: string; status: number }>(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        fields
      )
      .then((res) => {
        if (res?.data) {
          const { status, data } = res;
          const { message } = data;
          if (status && status === 401) {
            setFieldError('email', message);
            return;
          }
          if (data?.tokens && data?.user) {
            const { user, tokens } = data;

            // Save user info
            setUser({ ...user, ads: user?.ads || [] });
            setTokens(tokens);
            // Close modal
            setOpen(false);
            // Report created user type
            onSuccess?.(user.type);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setFieldError('email', error.response.data.message);
          return;
        }
      });
  };

  const handleClose = () => {
    // Reset state and close
    setOpen(false);
    onClose?.();
  };

  return (
    <Modal title={'Logga in till Svippo'} open={open} onClose={handleClose}>
      <LoginForm onValidSubmit={handleSubmit} />

      <div className={style.footer}>
        <span>Har du inte gått med i Svippo än?</span>
        <button
          className="link"
          onClick={() => {
            setOpen(false);
            setShowRegistration(true);
          }}
        >
          Skapa konto
        </button>
      </div>
    </Modal>
  );
};

export { LoginModal };
