import classNames from 'classnames';
import { HTMLProps, KeyboardEvent, useEffect, useId, useRef, useState } from 'react';
import { Card } from '../../card/card.component';
import style from './mega-radio-button.module.scss';

interface MegaRadioButtonProps extends HTMLProps<HTMLInputElement> {
  label: string;
}

export const MegaRadioButton = (props: MegaRadioButtonProps) => {
  const { id: _id, checked, defaultChecked, disabled, className, children, label, onChange, ...rest } = props;
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked || checked || false);

  const ref = useRef<HTMLInputElement>(null);
  const refChecked = ref?.current?.checked;

  useEffect(() => {
    if (ref.current) {
      setIsChecked(ref.current.checked);
    }
  }, [ref, refChecked]);

  const handleClick = () => {
    if (!disabled) {
      ref.current && ref.current.click();
      setIsChecked(true);
    }
  };

  const handleKeyboard = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  const randomId = useId();

  const id = _id || `svippo-radio-${randomId}`;

  return (
    <Card
      aria-labelledby={`${id}-label`}
      className={classNames(className, style.radiocard, isChecked && style.checked, disabled && style.disabled)}
      onClick={handleClick}
      onKeyUp={handleKeyboard}
    >
      <div className={style.container}>
        <input
          id={id}
          ref={ref}
          type="radio"
          className={style.radiobutton}
          checked={checked}
          disabled={disabled}
          defaultChecked={defaultChecked}
          onChange={onChange}
          {...rest}
        />
        <div className={style.contentcontainer}>
          <label
            htmlFor={id}
            id={`${id}-label`}
            className={classNames('heading-large', disabled ? 'text-gray-light' : 'text-blue-dark')}
          >
            {label}
          </label>
          {children}
        </div>
      </div>
    </Card>
  );
};
