import { PriceRange } from 'interfaces/pricerange.interface';
import { useEffect, useState } from 'react';
import { create } from 'zustand';
import { AdType } from '../constants/ad-type.enum';
import { PriceType } from '../constants/price-type.enum';
import { Ad } from '../interfaces/ad.interface';
import { apiService } from './api.service';
export interface AdsFilterOptions {
  type?: AdType;
  placeId?: number[];
  userId?: number | number[];
  priceType?: PriceType;
  categories?: number[];
  hourlyPriceFrom?: number;
  hourlyPriceTo?: number;
  fixedPriceFrom?: number;
  fixedPriceTo?: number;
  searchstring?: string;
}

export interface AdsPriceFilter
  extends Pick<
    AdsFilterOptions,
    'priceType' | 'hourlyPriceFrom' | 'hourlyPriceTo' | 'fixedPriceFrom' | 'fixedPriceTo'
  > {}

export interface AdsPaginationOptions {
  perPage: number;
  page: number;
}

interface AdsStore {
  allData: { [pageNumber: number]: Ad[] };
  data: Ad[];
  loaded: boolean;
  loading: boolean;
  count: number;
  filter: Omit<AdsFilterOptions, 'searchstring'>;
  setFilter: (filter: Omit<AdsFilterOptions, 'searchstring'>) => void;
  searchstring: string;
  setSearchstring: (searchstring: string) => void;
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  clearFilter: () => void;
}

export const useAdsStore = create<AdsStore>((set, get) => {
  const loadNewData = () => {
    set(() => ({ loading: true, page: 1 }));
    const state = get();
    let count = 0;
    let allData = {};
    apiService
      .get<Ad[]>('ads', {
        params: {
          filter: JSON.stringify({ ...state.filter, searchstring: state.searchstring }),
          limit: state.perPage,
          offset: state.page && state.perPage ? (state.page - 1) * state.perPage : 0,
        },
      })
      .then((res) => {
        if (res?.headers) {
          count = parseInt(res.headers['x-total-count'], 10);
        }
        if (res?.data) {
          set(() => ({ loaded: true, count, data: res.data, allData: { [state.page]: res.data }, loading: false }));
        }
      })
      .catch((err) => {
        console.log(err);
        set(() => ({ loaded: false, count, data: [], allData, loading: false }));
      });
  };

  const loadMoreData = () => {
    const state = get();
    let count = 0;
    if (state.allData[state.page]) {
      set(() => ({ loading: false, data: state.allData[state.page], loaded: true }));
    } else {
      apiService
        .get<Ad[]>('ads', {
          params: {
            filter: JSON.stringify({ ...state.filter, searchstring: state.searchstring }),
            limit: state.perPage,
            offset: state.page && state.perPage ? (state.page - 1) * state.perPage : 0,
          },
        })
        .then((res) => {
          if (res?.headers) {
            count = parseInt(res.headers['x-total-count'], 10);
          }
          if (res?.data) {
            set(() => ({
              loaded: true,
              data: res.data,
              count,
              allData: { ...state.allData, [state.page]: res.data },
              loading: false,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          set(() => ({ loaded: false, data: [], count, loading: false }));
        });
    }
  };

  return {
    allData: {},
    data: [],
    loaded: false,
    loading: false,
    count: 0,
    filter: {},
    setFilter: (filter) => {
      set(() => ({ filter }));
      loadNewData();
    },
    searchstring: '',
    setSearchstring: (searchstring) => {
      set(() => ({ searchstring }));
      loadNewData();
    },
    page: 1,
    setPage: (page) => {
      set(() => ({ page }));
      loadMoreData();
    },
    perPage: 10,
    setPerPage: (perPage) => {
      set(() => ({ perPage }));
      loadNewData();
    },
    clearFilter: () => {
      set(() => ({ filter: {}, searchstring: '', page: 1 }));
      loadNewData();
    },
  };
});

export const useAd = (id: string): { data?: Ad; loaded: boolean } => {
  const [data, setData] = useState<Ad>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(false);
    setData(undefined);
    apiService.get<Ad>(`ads/${id}`).then((res) => {
      if (res?.data) {
        setData(res.data);
        setLoaded(true);
      }
    });
  }, [id]);
  return { data, loaded };
};

const handleData = (data: { [key: string]: any }): FormData => {
  const formdata = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'otherCosts') {
      if (data.otherCostsCheckbox) {
        formdata.append(key, JSON.stringify(data[key]));
      }
    } else if (key === 'travelCost') {
      if (data.travelCostCheckbox) {
        formdata.append(key, JSON.stringify(data[key]));
      }
    } else if (key !== 'images') {
      formdata.append(key, JSON.stringify(data[key]));
    } else if (key === 'images') {
      data['images'].forEach((image: File) => {
        formdata.append('images', image);
      });
    }
  });
  return formdata;
};

export const createAd = (data: Partial<Ad>) => {
  return apiService
    .post<Ad>('user/ads', handleData(data), { headers: { 'Content-type': 'multipart/form-data' } })
    .then((res) => {
      if (res?.data) {
        return res.data;
      } else {
        throw new Error('Could not save ad');
      }
    });
};

export const usePricerange = (): { data: PriceRange; loaded: boolean } => {
  const [data, setData] = useState<PriceRange>({
    maxFixedPrice: 1000,
    minFixedPrice: 100,
    maxHourlyPrice: 500,
    minHourlyPrice: 50,
  });
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    apiService.get<PriceRange>('pricerange').then((res) => {
      if (res?.data) {
        setData(res.data);
        setLoaded(true);
      }
    });
  }, []);

  return { data, loaded };
};
