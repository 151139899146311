import { useEffect } from 'react';
import { create } from 'zustand';
import { ApiResponse, apiService } from './api.service';
import { Category } from '../interfaces/category.interface';

interface CategoryStore {
  categories: Category[];
  loaded: boolean;
  setCategories: (data: Category[]) => void;
  setLoaded: (loaded: boolean) => void;
}

const useCategoryStore = create<CategoryStore>((set) => ({
  categories: [],
  loaded: false,
  setCategories: (categories) => set({ categories, loaded: true }),
  setLoaded: (loaded) => set({ loaded }),
}));

export const useCategories = (): { data: Category[]; loaded: boolean } => {
  const { categories, setCategories, loaded } = useCategoryStore();

  useEffect(() => {
    if (categories.length < 1) {
      apiService.get<Category[]>('categories').then((res) => {
        if (res?.data) {
          setCategories(res.data);
        }
      });
    }
  }, [categories, setCategories]);

  return { data: categories, loaded: loaded };
};
