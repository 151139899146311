import { useEffect } from 'react';
import { create } from 'zustand';
import { Location } from '../interfaces/location.interface';
import { apiService } from './api.service';

interface LocationStore {
  locations: Location[];
  loaded: boolean;
  setLocations: (data: Location[]) => void;
  setLoaded: (loaded: boolean) => void;
}

const useLocationStore = create<LocationStore>((set) => ({
  locations: [],
  loaded: false,
  setLocations: (locations: Location[]) => set({ locations, loaded: true }),
  setLoaded: (loaded: boolean) => set({ loaded }),
}));

export const useLocations = (excludeOnline: boolean = false): { data: Location[]; loaded: boolean } => {
  const { locations, setLocations, loaded } = useLocationStore();

  useEffect(() => {
    if (locations.length < 1) {
      apiService.get<Location[]>('locations').then((res) => {
        if (res?.data) {
          setLocations(res.data);
        }
      });
    }
  }, [locations, setLocations]);

  return { data: excludeOnline ? locations.filter((loc) => loc.id !== 0) : locations, loaded: loaded };
};
