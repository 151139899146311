import { Modal } from 'components/modal';
import { AdsFilter } from './ads-filter.component';
import style from './ads-filter.module.scss';

interface AdsFilterModalProps {
  open: boolean;
  onClose: () => void;
}

export const AdsFilterModal: React.FC<AdsFilterModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} title="Filtrera sökning">
      <div className={style.column}>
        <AdsFilter />
        <hr />
        <button onClick={() => onClose()}>Applicera filter</button>
      </div>
    </Modal>
  );
};
