import { Fragment, HTMLAttributes, useId, useState } from 'react';
import style from './stepper.module.scss';
import classNames from 'classnames';
import CheckIcon from '@mui/icons-material/Check';

interface StepperProps extends Omit<HTMLAttributes<HTMLUListElement>, 'onClick'> {
  steps: string[];
  active: number;
  clickable?: number[];
  onClick?: (index: number) => void;
}

export const Stepper: React.FC<StepperProps> = (props) => {
  const { steps, active, className, id: _id, clickable, onClick, ...rest } = props;
  const [hover, setHover] = useState<number | null>(null);

  const randomId = useId();
  const id = _id || `stepper-${randomId}`;

  return (
    <ul id={id} className={style.stepper} role="menubar" {...rest}>
      {steps.map((step, index) => {
        const done = active > index;
        const isActive = active === index;
        const isClickable = done || clickable?.includes(index) || isActive;

        return (
          <Fragment key={`${id}-step-${index}`}>
            <li
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
              tabIndex={0}
              id={`${id}-step-${index}`}
              role="menuitem"
              aria-disabled={!isClickable}
              aria-current={isActive ? 'step' : 'false'}
              onClick={() => {
                isClickable && onClick && onClick(index);
              }}
              onKeyDown={(event) => {
                event.key === 'Enter' && isClickable && onClick && onClick(index);
              }}
              className={classNames({ [style.clickable]: isClickable })}
            >
              <div className={style.topWrapper}>
                <hr />
                <div
                  className={classNames(style.bullet, {
                    [style.active]: isActive,
                    [style.done]: done,
                    [style.disabled]: !isClickable,
                  })}
                >
                  {done && hover !== index ? <CheckIcon fontSize="small" /> : index + 1}
                </div>
                <hr />
              </div>
              <span className={style.label}>{step}</span>
            </li>
            {index + 1 < steps.length && <hr className={style.divider} />}
          </Fragment>
        );
      })}
    </ul>
  );
};
