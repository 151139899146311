import { Modal } from 'components/modal';
import { getFileUrl } from 'utils/get-local-url.util';
import style from './ad-addimg-modal.module.scss';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

interface AdImageModalProps {
  onClose: () => void;
  onDelete: (index: number) => void;
  onNext: () => void;
  onPrevious: () => void;
  src?: File;
  index: number;
}
export const AdImageModal: React.FC<AdImageModalProps> = ({ src, index, onClose, onDelete, onNext, onPrevious }) => {
  return !src ? (
    <></>
  ) : (
    <Modal open={!!src} onClose={onClose} title={`Bifogad bild ${index + 1}`}>
      <div className={style.wrapper}>
        <img className={style.image} src={getFileUrl(src)} alt="Bifogad bild" />
        <div className={style.buttonrow}>
          <button type="button" className="bullet large darkblue outlined" onClick={() => onPrevious()}>
            <KeyboardArrowLeftOutlinedIcon />
          </button>
          <button type="button" className="bullet large error solid" onClick={() => onDelete(index)}>
            <DeleteIcon />
          </button>
          <button type="button" className="bullet large darkblue outlined" onClick={() => onNext()}>
            <KeyboardArrowRightOutlinedIcon />
          </button>
        </div>
      </div>
    </Modal>
  );
};
