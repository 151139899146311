import { AdPage } from 'components/ad-page/ad-page.component';
import { useParams } from 'react-router-dom';
import { useAd } from 'services/ads.service';
import { Main } from '../../layouts/main/main.component';
import { useMediaQuery } from '@react-hookz/web';
import theme from 'styles/theme.module.scss';
import { WhiteBackground } from 'layouts/backgrounds/white-background/white-background.component';
import GradientBackground from 'layouts/backgrounds/gradient-background/gradient-background.component';

const LookAd = () => {
  const params = useParams();
  const id = params.id || '0';
  const { data, loaded } = useAd(id);

  const isXs = useMediaQuery(`screen and (max-width:${theme['breakpoint-xs']})`);

  return (
    <Main backgroundComp={isXs ? WhiteBackground : GradientBackground}>
      <AdPage data={data} loaded={loaded} />
    </Main>
  );
};

export default LookAd;
