import { ProfilePage } from 'components/profile-page/profile-page.component';
import { useUserStore } from '../../stores';
import { Spinner } from 'components/spinner/spinner.component';

const MyProfilePage = () => {
  const user = useUserStore((state) => state.user);

  return !user ? <Spinner size="large" /> : <ProfilePage user={user} edit />;
};

export { MyProfilePage };
