export enum DeadlineType {
  Asap = 'asap',
  None = 'none',
  Date = 'date',
}

export const DeadlineTypeLabels = {
  [DeadlineType.Asap]: 'Snarast',
  [DeadlineType.None]: 'Ingen dealine',
  [DeadlineType.Date]: 'Datum',
};
