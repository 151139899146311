import classNames from 'classnames';
import { ReactNode } from 'react';
import { Ad } from '../../interfaces/ad.interface';
import { AdCard } from '../ad-card/ad-card.component';
import style from './ad-list.module.scss';
interface AdListProps {
  ads: Ad[];
  errorMessage?: ReactNode;
}

export const AdList = (props: AdListProps) => {
  const { ads, errorMessage = 'Inga annonser' } = props;

  return (
    <>
      {ads.length < 1 ? (
        <div className={classNames(style.error, 'text-bold text-large')}>{errorMessage}</div>
      ) : (
        ads.map((ad, index) => <AdCard key={index} ad={ad} type={ad.type} />)
      )}
    </>
  );
};
