import { create } from 'zustand';
import { TOKENS_KEY, USER_KEY } from '../constants';
import { Tokens } from '../interfaces/tokens.interface';
import { User } from '../interfaces/user.interface';

interface UserStore {
  user?: User;
  tokens?: Tokens;
  setUser: (user?: User) => void;
  setTokens: (tokens?: Tokens) => void;
  clear: () => void;
}

const useUserStore = create<UserStore>((set) => {
  const userString = localStorage.getItem(USER_KEY);
  const tokensString = localStorage.getItem(TOKENS_KEY);
  const user = userString ? JSON.parse(userString) : undefined;
  const tokens = tokensString ? JSON.parse(tokensString) : undefined;
  return {
    user,
    tokens,
    setUser: (user) => {
      set({ user });
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    },
    setTokens: (tokens) => {
      set({ tokens });
      localStorage.setItem(TOKENS_KEY, JSON.stringify(tokens));
    },
    clear: () => {
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(TOKENS_KEY);
      set({
        user: undefined,
        tokens: undefined,
      });
    },
  };
});

export type { UserStore };
export { useUserStore };
