import classNames from 'classnames';
import { HTMLProps } from 'react';
import './grid.module.scss';

interface GridProps extends HTMLProps<HTMLDivElement> {
  error?: string;
  formgrid?: boolean;
}

const Grid = (props: GridProps) => {
  const { className, children, formgrid = false, ...rest } = props;

  return (
    <div className={classNames('gridContainer', formgrid && 'formgrid', className)} {...rest}>
      {children}
    </div>
  );
};

interface ColumnProps extends Omit<HTMLProps<HTMLDivElement>, 'span' | 'start'> {
  /* Number of columns to span. Array for L, M, S, XS - in that order. Or number for same in all views */
  span?: number | number[];
  /* Startposition of the column in grid. Array for L, M, S, XS - in that order. Or number for same in all views */
  start?: number | number[];
  flex?: 'row' | 'column';
}

const Column = (props: ColumnProps) => {
  const { span, start, className, children, flex, ...rest } = props;

  const getSpanClassName = () => {
    if (span) {
      const newSpan = span === 12 ? [12, 12, 12, 4] : span;

      if (typeof newSpan === 'number') {
        return `col-span-${newSpan}`;
      }
      if (newSpan && newSpan.length > 0) {
        return `col-span-${newSpan[0]} col-span-m-${newSpan[1]} col-span-s-${newSpan[2]} col-span-xs-${newSpan[3]}`;
      }
    }
  };
  const getStartClassName = () => {
    if (start) {
      if (typeof start === 'number') {
        return `col-start-${start}`;
      }
      if (start && start.length > 0) {
        return `col-start-${start[0]} col-start-m-${start[1]} col-start-s-${start[2]} col-start-xs-${start[3]}`;
      }
    }
  };

  return (
    <div
      className={classNames(
        'gridColumn',
        getStartClassName(),
        getSpanClassName(),
        flex === 'row' ? 'flexrow' : flex === 'column' ? 'flexcolumn' : '',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export { Grid, Column };
