import classNames from 'classnames';
import { KeyboardEvent, createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserStore } from '../../stores';
import style from './usermenu.module.scss';
import profileSrc from 'assets/illustrations/avatar.png';

export const UserMenu = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [onLogout, user] = useUserStore((state) => [state.clear, state.user]);

  const buttonRef = createRef<HTMLDivElement>();
  const ref = createRef<HTMLDivElement>();
  const toggleDropdown = useCallback(() => {
    setDropdownVisible(!dropdownVisible);
  }, [dropdownVisible]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current?.contains(event.target as any) &&
        buttonRef.current &&
        !buttonRef.current?.contains(event.target as any)
      ) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef, ref]);

  const menuItems = (
    <>
      <Link to="/myprofile" onClick={() => setDropdownVisible(false)}>
        Min profil
      </Link>
      <Link to="#" onClick={() => setDropdownVisible(false)}>
        Mina meddelanden
      </Link>
      <hr />
      {user?.svipper ? (
        <Link to="#" onClick={() => setDropdownVisible(false)}>
          Mina tjänster
        </Link>
      ) : (
        <Link to="/become-svippare" onClick={() => setDropdownVisible(false)}>
          Bli en svippare
        </Link>
      )}
      <Link to="#" onClick={() => setDropdownVisible(false)}>
        Mina förfrågningar
      </Link>
      <hr />
      <Link to="#" onClick={() => setDropdownVisible(false)}>
        Inställningar
      </Link>
      <hr />
      <Link to="/" onClick={onLogout}>
        Logga ut
      </Link>
    </>
  );

  const handleEscape = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      setDropdownVisible(false);
    }
  };

  return (
    <>
      <div ref={buttonRef} className={style.profileWrapper} onKeyDown={handleEscape}>
        <button className="bullet menu" onClick={toggleDropdown}>
          <img src={user?.avatar?.fullUrl || profileSrc} alt="Användarmeny" className={style.iconUser}></img>
        </button>

        <div className={classNames(style.dropdown, dropdownVisible ? style.visible : '')}>{menuItems}</div>
      </div>
      {dropdownVisible && (
        <div className={classNames(style.mobilemenu)}>
          <div ref={ref} className={classNames(style.dropdown, style.visible)} onKeyDown={handleEscape}>
            {menuItems}
          </div>
        </div>
      )}
    </>
  );
};
