import { Card } from 'components/card/card.component';
import { FormField } from 'components/forms';
import { Checkbox } from 'components/forms/checkbox/checkbox.component';
import styleFromFormField from 'components/forms/form-field/form-field.module.scss';
import { Column, Grid } from 'components/grid/grid.component';
import { AccountType } from 'constants/account-type.enum';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { useUserStore } from 'stores';

export const AccountInfoBusiness = () => {
  const { user } = useUserStore();

  return (
    <Card>
      <h2 className="heading-small text-blue-dark">Grundinformation</h2>
      <FormField
        fieldSize="large"
        type="text"
        id="companyName"
        name="companyName"
        label="Registrerat bolagsnamn*"
        placeholder="Företag AB"
        required
      />

      <FormField
        fieldSize="large"
        type="text"
        disallow={/[^0-9-]/}
        maxLength={13}
        id="orgNumber"
        name="orgNumber"
        label="Organisationsnummer*"
        disabled={user?.type && user.type === AccountType.Company && !!user.orgNumber}
        placeholder="123456-7890"
      />
      <FormField fieldSize="large" type="text" id="adress" name="adress" label="Adress*" placeholder="Gatuvägen 1" />
      <Grid formgrid>
        <Column span={6}>
          <FormField
            fieldSize="large"
            disallow={/[^0-9\ ]/}
            type="text"
            id="postCode"
            name="postCode"
            label="Postnummer*"
            placeholder="123 45"
            maxLength={6}
          />
        </Column>
        <Column span={6}>
          <FormField
            fieldSize="large"
            type="text"
            id="city"
            name="city"
            label="Postadress"
            disabled={false}
            placeholder="Ortsnamn"
          />
        </Column>
        <Column span={12}>
          <Field name="tax">
            {({ field }: FieldProps) => (
              <Checkbox
                label="Företaget innehar F-skattesedel*"
                {...field}
                id="tax"
                checked={field.value ? true : false}
              />
            )}
          </Field>
          <ErrorMessage name="tax">
            {(errorMessage: string) => (
              <div className={`${styleFromFormField.error}`}>
                <img src="/static/media/icon-error.9793803858ddef6d644ee645640bde7e.svg" alt="" />
                {errorMessage}
              </div>
            )}
          </ErrorMessage>
        </Column>
      </Grid>
    </Card>
  );
};
