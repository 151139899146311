import { EditOutlined } from '@mui/icons-material';
import { useMediaQuery } from '@react-hookz/web';
import classNames from 'classnames';
import { AdList } from 'components/ad-list/ad-list.component';
import { Pagination } from 'components/pagination/pagination.component';
import { User } from 'interfaces/user.interface';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import themeVars from 'styles/theme.module.scss';
import style from './profile-page-ads.module.scss';
import { AdType } from 'constants/ad-type.enum';
import { useUserStore } from 'stores';
interface ProfilePageAdsProps {
  user: User;
  edit: boolean;
}

export const ProfilePageAds: React.FC<ProfilePageAdsProps> = ({ user, edit }) => {
  const [page, setPage] = useState<number>(1);
  const ads = user.ads.filter((ad) => ad.type === AdType.Service);
  const myUser = useUserStore((state) => state.user);
  const adsToShow = 10;

  const isMobile = useMediaQuery(`screen and (max-width:${themeVars['breakpoint-s']})`);

  const getHeader = () => {
    if (myUser && user.id === myUser.id) {
      return 'Mina tjänster';
    } else if (RegExp(/[sS]/).test(user.publicName.substring(user.publicName.length - 1) || '')) {
      return `${user.publicName} tjänster`;
    } else {
      return `${user.publicName}s tjänster`;
    }
  };
  return (
    <>
      <h2 className={classNames(style.heading, 'heading-standard')}>{getHeader()}</h2>
      <AdList ads={ads.slice(isMobile ? 0 : (page - 1) * adsToShow, page * adsToShow)} />
      {ads.length > adsToShow && (
        <Pagination
          count={ads.length}
          page={page}
          perPage={adsToShow}
          onChangePage={setPage}
          className={style.bottommargin}
        />
      )}

      {edit && (
        <div className={style.buttonwrapper}>
          <Link to="/" className="button outlined blue">
            Se alla tjänster
          </Link>
          <Link to="/create-ad" className="button solid red iconLeft">
            <EditOutlined />
            Skapa inlägg
          </Link>
        </div>
      )}
    </>
  );
};
