import classNames from 'classnames';
import { Checkbox } from 'components/forms/checkbox/checkbox.component';
import { useLocations } from 'services/locations.service';
import style from './ads-filter.module.scss';
import { Spinner } from 'components/spinner/spinner.component';

interface AdsFilterLocationsProps {
  value: number[];
  onChange: (value: number[]) => void;
}

export const AdsFilterLocations: React.FC<AdsFilterLocationsProps> = ({ value, onChange }) => {
  const { data, loaded } = useLocations();

  const handleChange = (id: number) => {
    if (value.includes(id)) {
      const newValues = value.filter((loc) => loc !== id);
      onChange(newValues);
    } else {
      onChange([...value, id]);
    }
  };

  return !loaded ? (
    <Spinner />
  ) : (
    <fieldset aria-label="Plats" className={classNames(style.column, style.tight)}>
      {data.map((loc, index) => (
        <Checkbox
          key={`loc-${index}-${loc.id}`}
          label={loc.label}
          value={loc.id.toString()}
          checked={value.includes(loc.id)}
          onChange={() => handleChange(loc.id)}
        />
      ))}
    </fieldset>
  );
};
