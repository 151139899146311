import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { validNamePattern } from '../../../constants';
import { FormField } from '../form-field';
import styleFromFormField from '../form-field/form-field.module.scss';
import style from '../forms.module.scss';

interface RegisterPrivateFormProps extends HTMLProps<HTMLDivElement> {
  onValidSubmit: (values: { [key: string]: any }, helpers: FormikHelpers<any>) => void;
}

const RegisterPrivateForm = (props: RegisterPrivateFormProps) => {
  const { onValidSubmit } = props;

  return (
    <div className={style.container}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          passwordRepeat: '',
          terms: false,
        }}
        onSubmit={(values, helpers) => onValidSubmit(normalizeValues(values), helpers)}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange
      >
        {({ handleChange, handleBlur }) => (
          <Form>
            <div className={style.row}>
              <FormField
                fieldSize="large"
                label={'Förnamn'}
                type={'text'}
                name={'firstName'}
                id={'firstName'}
                className={style.field}
                autoComplete="given-name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormField
                fieldSize="large"
                label={'Efternamn'}
                type={'text'}
                name={'lastName'}
                id={'lastName'}
                className={style.field}
                autoComplete="family-name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <FormField
              fieldSize="large"
              label={'E-post'}
              type={'email'}
              placeholder={'exempel@exempel.com'}
              name={'email'}
              id={'email'}
              className={style.field}
              autoComplete="email"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormField
              fieldSize="large"
              label={'Lösenord'}
              type={'password'}
              name={'password'}
              id={'password'}
              className={style.field}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormField
              fieldSize="large"
              label={'Upprepa lösenord'}
              type={'password'}
              name={'passwordRepeat'}
              id={'passwordRepeat'}
              className={style.field}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <div className={style.checkbox}>
              <Field name="terms">
                {({ field }: FieldProps) => (
                  <>
                    <input type="checkbox" id="terms" checked={field.value} {...field} />
                    <label htmlFor="terms">
                      <span>Jag godkänner </span>
                      <Link to="/">Svippos villkor for användare</Link>
                    </label>
                  </>
                )}
              </Field>
            </div>

            <ErrorMessage name="terms">
              {(error: string) => (
                <div className={`${styleFromFormField.error}`}>
                  <img src="/static/media/icon-error.9793803858ddef6d644ee645640bde7e.svg" alt="" />
                  {error}
                </div>
              )}
            </ErrorMessage>

            <button type="submit" className="primary large">
              Skapa konto
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Förnamn krävs')
    .test('isValidName', 'Ogiltligt förnamn', (value) => validNamePattern.test(value)),
  lastName: Yup.string()
    .required('Efternamn krävs')
    .test('isValidName', 'Ogiltligt efternamn', (value) => validNamePattern.test(value)),
  email: Yup.string().email('Ogiltlig E-postaddress').required('E-postaddress krävs'),
  password: Yup.string().required('Lösenord krävs'),
  passwordRepeat: Yup.string()
    .required('Upprepat lösenord krävs')
    .test('matchingPassword', 'Upprepat lösenord stämmer inte', function (value) {
      const { password } = this.parent;
      return value === password;
    }),
  terms: Yup.bool().required('Du måste godkänna villkoren').isTrue('Du måste godkänna villkoren'),
});

const normalizeValues = (values: { [key: string]: any }) => {
  // Make names capitalized lowercase
  const firstName = values.firstName.charAt(0).toUpperCase() + values.firstName.toLowerCase().slice(1);
  const lastName = values.lastName.charAt(0).toUpperCase() + values.lastName.toLowerCase().slice(1);

  return {
    ...values,
    firstName,
    lastName,
  };
};

export { RegisterPrivateForm };
