import { Form, Formik, FormikHelpers } from 'formik';
import { HTMLProps } from 'react';
import { validEmailPattern } from '../../../constants';
import { FormField } from '../form-field';
import { LoginPayload } from '../../../interfaces/login.interface';
import style from '../forms.module.scss';

interface LoginFormProps extends HTMLProps<HTMLDivElement> {
  onValidSubmit: (values: LoginPayload, helpers: FormikHelpers<LoginPayload>) => void;
}

const LoginForm = (props: LoginFormProps) => {
  const { onValidSubmit } = props;

  return (
    <div className={style.container}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={onValidSubmit}
        validate={(values: any) => validateForm(values)}
        validateOnMount={true}
      >
        {({ values, isValid, resetForm }) => (
          <Form>
            <FormField
              fieldSize="large"
              label={'E-post'}
              type={'text'}
              name={'email'}
              id={'email'}
              value={values.email}
              className={style.field}
              placeholder={'example@example.com'}
            />

            <FormField
              fieldSize="large"
              label={'Lösenord'}
              type={'password'}
              name={'password'}
              id={'password'}
              value={values.password}
              className={style.field}
            />

            <button
              type="submit"
              className="primary"
              disabled={!isValid}
              // onClick={() => {
              //   resetForm({ values });
              //   onValidSubmit(normalizeValues(values));
              // }}
            >
              Logga in
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const validateForm = (values: LoginPayload) => {
  const errors: { [key: string]: string | undefined } = {
    email: undefined,
    password: undefined,
  };

  // Check that e-mail is provided
  if (!values.email) {
    errors.email = 'E-postaddress krävs';
  }
  // Check that e-mail is correctly formatted
  else if (!validEmailPattern.test(values.email)) {
    errors.email = 'Ogiltlig E-postaddress';
  }

  // Check that password is provided
  if (!values.password) {
    errors.password = 'Lösenord krävs';
  }

  // Remove any undefined fields or Formik will consider it invalid
  return Object.keys(errors)
    .filter((key) => errors[key] !== undefined)
    .reduce((errorObj: { [key: string]: any }, key) => {
      errorObj[key] = errors[key];
      return errorObj;
    }, {});
};

const normalizeValues = (values: LoginPayload): LoginPayload => {
  return {
    ...values,
  };
};

export { LoginForm };
