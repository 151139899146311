import { HTMLProps } from 'react';
import classNames from 'classnames';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import style from './modal.module.scss';

interface ModalProps extends HTMLProps<HTMLDivElement> {
  open: boolean;
  title: string;
  onClose?: () => void;
}

const Modal = (props: ModalProps) => {
  const { title, onClose, className, children, open } = props;

  return open ? (
    <div className={style.background}>
      <div className={classNames(style.container, className)}>
        <div className={style.header}>
          <span className={style.title}>{title}</span>
          {onClose && (
            <button
              type="button"
              className={classNames('bullet inverted darkblue', style.closebutton)}
              onClick={() => onClose()}
            >
              <HighlightOffOutlinedIcon />
            </button>
          )}
        </div>

        {children}
      </div>
    </div>
  ) : null;
};

export type { ModalProps };
export { Modal };
