import { create } from 'zustand';

interface ModalsStore {
  showLogin: boolean;
  showRegistration: boolean;
  showLogedInBox: boolean;

  setShowLogin: (show: boolean) => void;
  setShowRegistration: (show: boolean) => void;
  setShowLogedInBox: (show: boolean) => void;
}

const useModalsStore = create<ModalsStore>((set) => {
  return {
    showLogin: false,
    showRegistration: false,
    showLogedInBox: false,

    setShowLogin: (showLogin) => set({ showLogin }),
    setShowRegistration: (showRegistration) => set({ showRegistration }),
    setShowLogedInBox: (showLogedInBox) => set({ showLogedInBox }),
  };
});

export type { ModalsStore };
export { useModalsStore };
