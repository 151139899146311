import { Card } from 'components/card/card.component';
import { User } from 'interfaces/user.interface';
import { useState } from 'react';
import { useUserStore } from 'stores';
import classNames from 'classnames';
import style from './profile-page-presentation.module.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormField } from 'components/forms';
import { Form, Formik } from 'formik';
import { updateUser } from 'services/user.service';

interface ProfilePagePresentationProps {
  user: User;
  canEdit?: boolean;
}

export const ProfilePagePresentation: React.FC<ProfilePagePresentationProps> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { user, canEdit: _canEdit } = props;

  const [myUser, setUser] = useUserStore((state) => [state.user, state.setUser]);

  const canEdit = _canEdit && user.id === myUser?.id;

  const handleSubmit = (values: { headline: string; presentation: string }) => {
    if (canEdit) {
      updateUser(values).then((res) => {
        if (res) {
          setUser(res);
          setIsEdit(false);
        }
      });
    }
  };
  return (
    <Card className={style.container}>
      <Formik
        initialValues={{ headline: user.headline || '', presentation: user.presentation || '' }}
        onSubmit={handleSubmit}
      >
        {isEdit ? (
          <Form className="flexcolumn">
            <FormField
              fieldSize="large"
              label="Rubrik*"
              placeholder="Här är jag..."
              type="text"
              name="headline"
              id={'headline'}
              required
            />

            <FormField
              fieldSize="large"
              label="Presentationstext*"
              type="textarea"
              placeholder="Berätta något intressant om dig själv..."
              id="presentation"
              name="presentation"
              disabled={false}
              rows={10}
              required
            />
            <button type="submit" className="primary">
              Spara ändringar
            </button>
          </Form>
        ) : (
          <>
            {canEdit && (
              <button
                className={classNames('inverted darkblue bullet', style.editbutton)}
                onClick={() => setIsEdit(true)}
              >
                <EditOutlinedIcon />
              </button>
            )}
            <h1 className="heading-large text-blue-dark">{user.headline}</h1>
            <p>{user.presentation}</p>
          </>
        )}
      </Formik>
    </Card>
  );
};
