import Birdie from 'assets/illustrations/svippo-symbol.svg';
import style from './spinner.module.scss';
import classNames from 'classnames';

interface SpinnerProps {
  size?: 'small' | 'large';
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
  const { size = 'small' } = props;
  return (
    <div className={style.wrapper}>
      <div className={classNames(style.spinnerBox, { [style.large]: size === 'large' })}>
        <span className={classNames(style.spinner, { [style.large]: size === 'large' })}>
          <img src={Birdie} alt="loading..." />
        </span>
      </div>
    </div>
  );
};
