import { PriceType } from 'constants/price-type.enum';
import { Formik } from 'formik';
import { AdsPriceFilter, usePricerange } from 'services/ads.service';
import style from './ads-filter.module.scss';
import { Spinner } from 'components/spinner/spinner.component';
import { FormField } from 'components/forms';

interface AdsFilterPriceProps {
  value: AdsPriceFilter;
  onChange: (filter: AdsPriceFilter) => void;
}

export const AdsFilterPrice: React.FC<AdsFilterPriceProps> = ({ value, onChange }) => {
  const { data: pricerange, loaded } = usePricerange();

  const initialValues: AdsPriceFilter = {
    hourlyPriceFrom: pricerange.minHourlyPrice,
    hourlyPriceTo: pricerange.maxHourlyPrice,
    fixedPriceFrom: pricerange.minFixedPrice,
    fixedPriceTo: pricerange.maxFixedPrice,
  };

  return !loaded ? (
    <Spinner />
  ) : (
    <div className={style.column}>
      <fieldset aria-label="Prissättning" className={style.row}>
        <span>
          <input
            checked={!value.priceType}
            name="priceType"
            type="radio"
            id="priceTypeAll"
            value=""
            onChange={() => onChange({ ...value, priceType: undefined })}
          />
          <label htmlFor="priceTypeAll">Alla</label>
        </span>
        <span>
          <input
            checked={value.priceType === PriceType.Fixed}
            name="priceType"
            type="radio"
            id="priceTypeFixed"
            value={PriceType.Fixed}
            onChange={() => onChange({ ...value, priceType: PriceType.Fixed })}
          />
          <label htmlFor="priceTypeFixed">Fast pris</label>
        </span>
        <span>
          <input
            checked={value.priceType === PriceType.Hourly}
            name="priceType"
            type="radio"
            id="priceTypeHourly"
            value={PriceType.Hourly}
            onChange={() => onChange({ ...value, priceType: PriceType.Hourly })}
          />
          <label htmlFor="priceTypeHourly">Timpris</label>
        </span>
      </fieldset>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ values }) => (
          <>
            {value?.priceType !== PriceType.Hourly && (
              <fieldset aria-label="Fast prisomfång" className={style.row}>
                <FormField
                  type="number"
                  name="fixedPriceFrom"
                  min={pricerange.minFixedPrice}
                  max={values.fixedPriceTo}
                  step={100}
                  value={value.fixedPriceFrom || pricerange.minFixedPrice}
                  label="Fast pris från"
                  onChange={(e) => {
                    const priceFrom = parseInt(e.target.value, 10);

                    if (values?.fixedPriceTo !== undefined && priceFrom <= values?.fixedPriceTo) {
                      onChange({
                        ...value,
                        fixedPriceFrom: priceFrom,
                        fixedPriceTo: values.fixedPriceTo,
                      });
                    }
                  }}
                ></FormField>
                <FormField
                  type="number"
                  name="fixedPriceTo"
                  min={values.fixedPriceFrom}
                  max={pricerange.maxFixedPrice}
                  value={value.fixedPriceTo || pricerange.maxFixedPrice}
                  step={100}
                  label="Fast pris till"
                  onChange={(e) => {
                    const priceTo = parseInt(e.target.value, 10);

                    if (values?.fixedPriceFrom !== undefined && priceTo >= values?.fixedPriceFrom) {
                      onChange({
                        ...value,
                        fixedPriceFrom: values.fixedPriceFrom,
                        fixedPriceTo: priceTo,
                      });
                    }
                  }}
                ></FormField>
              </fieldset>
            )}
            {value?.priceType !== PriceType.Fixed && (
              <fieldset aria-label="Timprisomfång" className={style.row}>
                <FormField
                  type="number"
                  name="hourlyPriceFrom"
                  min={pricerange.minHourlyPrice}
                  max={values.hourlyPriceTo}
                  step={50}
                  value={value.hourlyPriceFrom || pricerange.minHourlyPrice}
                  label="Timpris från"
                  onChange={(e) => {
                    const priceFrom = parseInt(e.target.value, 10);

                    if (values?.hourlyPriceTo !== undefined && priceFrom <= values?.hourlyPriceTo) {
                      onChange({
                        ...value,
                        hourlyPriceFrom: priceFrom,
                        hourlyPriceTo: values.hourlyPriceTo,
                      });
                    }
                  }}
                ></FormField>
                <FormField
                  type="number"
                  name="hourlyPriceTo"
                  min={values.hourlyPriceFrom}
                  max={pricerange.maxHourlyPrice}
                  step={50}
                  value={value.hourlyPriceTo || pricerange.maxHourlyPrice}
                  label="Timpris till"
                  onChange={(e) => {
                    const priceTo = parseInt(e.target.value, 10);

                    if (values?.hourlyPriceFrom !== undefined && priceTo >= values?.hourlyPriceFrom) {
                      onChange({
                        ...value,
                        hourlyPriceFrom: values.hourlyPriceFrom,
                        hourlyPriceTo: priceTo,
                      });
                    }
                  }}
                ></FormField>
              </fieldset>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};
