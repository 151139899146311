import { Link } from 'react-router-dom';
import svipperImg from '../../assets/illustrations/sell-services.png';
import style from './svipperbox.module.scss';

const SvipperBox = () => {
  return (
    <>
      <div>
        <span className={style.heading}>Bli en svippare!</span> <br />
        <p className={style.text}>För att kunna skapa en tjänsteannons måste du först registrera dig som svippare.</p>
        <Link to="/become-svippare" className="button red">
          Kom igång
        </Link>
      </div>
      <img className={style.img} alt="selling with partner" src={svipperImg} />
    </>
  );
};

export { SvipperBox };
