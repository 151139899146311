import { Accordion } from 'components/accordion/accordion.component';
import { useMemo, useState } from 'react';
import { AdsFilterOptions, AdsPriceFilter, useAdsStore } from 'services/ads.service';
import { AdsFilterAdType } from './ads-filter-adtype.component';
import { AdsFilterCategories } from './ads-filter-categories.component';
import { AdsFilterLocations } from './ads-filter-location.component';
import { AdsFilterPrice } from './ads-filter-price.component';
import { AdsFilterSummary } from './ads-filter-summary.component';
import style from './ads-filter.module.scss';

export const AdsFilter: React.FC = () => {
  const [filter, setFilter] = useAdsStore((state) => [state.filter, state.setFilter]);
  const { type, categories, placeId, ...priceFilter } = useMemo(() => filter, [filter]);
  const [priceOpen, setPriceOpen] = useState<boolean>(false);

  const setPriceFilter = (priceFilter: AdsPriceFilter) => {
    const { type, categories, placeId } = filter;
    setFilter({ type, categories, placeId, ...priceFilter });
  };

  const setPlaceFilter = (places: number[]) => {
    if (places.length) {
      setFilter({ ...filter, placeId: places });
    } else {
      const { placeId, ...rest } = filter;
      setFilter(rest);
    }
  };

  const handleRemoveFilter = (newFilter: AdsFilterOptions) => {
    const { type, categories, placeId, ...price } = newFilter;
    if (placeId && placeId?.length > 0) {
      setFilter(newFilter);
    } else {
      setFilter({ type, categories, ...price });
    }
  };

  return (
    <div className={style.column}>
      <Accordion label="Annonstyp" open={true}>
        <AdsFilterAdType value={type} onChange={(type) => setFilter({ ...filter, type })} />
      </Accordion>
      <Accordion label="Kategorier" open={true}>
        <AdsFilterCategories value={categories || []} onChange={(categories) => setFilter({ ...filter, categories })} />
      </Accordion>
      <Accordion label="Plats" open={false}>
        <AdsFilterLocations value={placeId || []} onChange={setPlaceFilter} />
      </Accordion>
      <Accordion label="Pris" open={false} onToggle={setPriceOpen}>
        <AdsFilterPrice value={priceFilter} onChange={setPriceFilter} />
      </Accordion>
      {priceOpen && <hr />}
      <AdsFilterSummary filter={filter} onChange={handleRemoveFilter} />
    </div>
  );
};
