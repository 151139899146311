import { Checkbox } from 'components/forms/checkbox/checkbox.component';
import { useCategories } from 'services/categories.service';
import style from './ads-filter.module.scss';
import classNames from 'classnames';
import { Spinner } from 'components/spinner/spinner.component';

interface AdsFilterCategoriesProps {
  value: number[];
  onChange: (value: number[]) => void;
}

export const AdsFilterCategories: React.FC<AdsFilterCategoriesProps> = ({ value, onChange }) => {
  const { data, loaded } = useCategories();

  const handleChange = (id: number) => {
    if (value.includes(id)) {
      const newValues = value.filter((cat) => cat !== id);
      onChange(newValues);
    } else {
      onChange([...value, id]);
    }
  };

  return !loaded ? (
    <Spinner />
  ) : (
    <fieldset aria-label="Kategorier" className={classNames(style.column, style.tight)}>
      {data.map((cat, index) => (
        <Checkbox
          key={`cat-${index}-${cat.id}`}
          label={cat.label}
          value={cat.id.toString()}
          checked={value.includes(cat.id)}
          onChange={() => handleChange(cat.id)}
        />
      ))}
    </fieldset>
  );
};
