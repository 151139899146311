import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { Tag } from 'components/tag/tag.component';
import { PriceType } from 'constants/price-type.enum';
import { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import profileSrc from '../../assets/illustrations/avatar.png';
import { AdType } from '../../constants/ad-type.enum';
import { Ad } from '../../interfaces/ad.interface';
import { Category } from '../../interfaces/category.interface';
import style from './ad-card.module.scss';

interface AdCardProps extends HTMLProps<HTMLInputElement> {
  ad: Ad;
}

export const AdCard = (props: AdCardProps) => {
  const { ad, className } = props;

  const priceText =
    ad.type === AdType.Request
      ? 'budget'
      : !!ad.otherCosts || (typeof ad.travelCost === 'number' && ad.travelCost > 0)
      ? 'från'
      : '';
  const priceValue = ad.priceType === PriceType.Fixed ? ad.fixedPrice : ad.hourlyPrice;
  const priceUnit = ad.priceType === PriceType.Fixed ? 'kr' : 'kr/h';

  function formatDate(adCreatedAt: string): string {
    const adDate = new Date(adCreatedAt);
    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    const sameDay = (d1: Date, d2: Date): boolean =>
      d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();

    if (sameDay(adDate, now)) {
      return 'idag ' + adDate.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' });
    } else if (sameDay(adDate, yesterday)) {
      return 'igår ' + adDate.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' });
    } else {
      return (
        adDate.toLocaleString('sv-SE', { day: 'numeric', month: 'numeric' }) +
        ' ' +
        adDate.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' })
      );
    }
  }

  return (
    <Link
      to={`/ads/${ad.id}`}
      className={classNames('card', className, style.bars, {
        [style.requestBar]: ad.type === AdType.Request,
      })}
    >
      {ad.type === AdType.Service && (
        <div className={style.imgBox}>
          {ad.images.length > 0 ? (
            <div style={{ backgroundImage: `url(${ad.images[0].fullUrl})` }} className={style.adimage}>
              {' '}
            </div>
          ) : (
            <Avatar alt="" src={ad.user?.avatar?.fullUrl || profileSrc} sx={{ height: 120, width: 120 }} />
          )}
        </div>
      )}
      <div className={style.serviceItem}>
        <div className={style.info}>
          <div className={style.headerinfobigscreen}>
            <div className={classNames(style.left, { [style.serviceLeft]: ad.type === AdType.Service })}>
              <p className="text-standard semibold">
                {ad.type === AdType.Request
                  ? 'Förfrågan'
                  : ad.user && ad.user.publicName
                  ? ad.user.publicName
                  : 'Namn saknas'}
              </p>
              <p className={style.place}>{ad.place?.label}</p>
            </div>

            <p className={style.date}>{ad.createdAt ? formatDate(ad.createdAt) : undefined}</p>
          </div>

          <p className={classNames(style.centercontent, 'semibold')}>
            {ad.type === AdType.Request ? `Sökes: ${ad.headline}` : ad.headline}
          </p>
          <div className={style.bottominfo}>
            <div className={style.headerinfosmallscreen}>
              <div className={classNames(style.left, { [style.serviceLeft]: ad.type === AdType.Service })}>
                <p className="text-standard semibold">
                  {ad.type === AdType.Request
                    ? 'Förfrågan'
                    : ad.user && ad.user.publicName
                    ? ad.user.publicName
                    : 'Namn saknas'}
                </p>
                <p className={style.place}>{ad.place?.label}</p>
              </div>
            </div>
            <div className={style.categories}>
              {ad?.categories &&
                ad.categories.map((cat: Category, index: number) => (
                  <Tag key={`cat-${cat.id}-${index}`} color={ad.type === AdType.Request ? 'red' : 'blue'}>
                    {cat.label}
                  </Tag>
                ))}
            </div>
            <div>
              <span className={style.priceText}>{priceText}</span>{' '}
              <span className={style.priceValueAndUnit}>{priceValue ? `${priceValue} ${priceUnit}` : ''}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
