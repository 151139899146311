import React, { ReactNode } from 'react';
import style from './gradient-background.module.scss';

type GradientBackgroundProps = {
  children: ReactNode;
};

const GradientBackground: React.FC<GradientBackgroundProps> = ({ children }) => (
  <div className={style.background}>{children}</div>
);

export default GradientBackground;
