import { ProfilePage } from 'components/profile-page/profile-page.component';
import { useParams } from 'react-router-dom';
import { useUser } from 'services/user.service';

export const SingleProfile = () => {
  const params = useParams();
  const { user, loaded } = useUser(params?.id);

  return <ProfilePage user={user} loaded={loaded} />;
};
