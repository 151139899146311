import { ReactNode } from 'react';
import style from './page-header.module.scss';
import SummerBackground from 'layouts/backgrounds/summer-background/summer-background.component';

interface PageHeaderProps {
  children?: ReactNode;
  backgroundComp?: React.FC<{ children: ReactNode }>;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ children, backgroundComp: Background = SummerBackground }) => {
  return (
    <header className={style.header}>
      <Background>
        <div className={style.content}>{children}</div>
      </Background>
    </header>
  );
};
