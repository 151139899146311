import { Error } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from 'classnames';
import { FormikValues, useField, useFormikContext } from 'formik';
import { ChangeEvent, FocusEvent, HTMLProps, useEffect, useState } from 'react';
import style from './form-field.module.scss';

//TODO: Add standard and large size. Everything is Large now.

interface IFormFieldProps {
  label?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  endAdornment?: boolean;
  afterinput?: string;
  helpertext?: string;
  name?: string;
  error?: string;
  disallow?: RegExp;
  fieldSize?: 'standard' | 'large';
}
interface FormFieldInputProps extends IFormFieldProps, Omit<HTMLProps<HTMLInputElement>, 'label' | 'name'> {
  type:
    | 'number'
    | 'text'
    | 'date'
    | 'file'
    | 'color'
    | 'datetime-local'
    | 'email'
    | 'range'
    | 'tel'
    | 'time'
    | 'url'
    | 'week'
    | 'password'
    | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}
interface FormFieldTextareaProps extends IFormFieldProps, Omit<HTMLProps<HTMLTextAreaElement>, 'label' | 'name'> {
  type: 'textarea';
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}
interface FormFieldSelectProps extends IFormFieldProps, Omit<HTMLProps<HTMLSelectElement>, 'label' | 'name'> {
  type: 'select';
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

type FormFieldProps = FormFieldInputProps | FormFieldSelectProps | FormFieldTextareaProps;

const FormField = (props: FormFieldProps) => {
  const {
    label,
    name = '',
    className,
    afterinput,
    type,
    id,
    children,
    helpertext,
    value: _value,
    onChange: _onChange,
    onBlur,
    disallow,
    error: _error,
  } = props;

  const { fieldSize = 'standard', endAdornment = type === 'select', ...restProps } = props;

  const { setFieldValue, validateField } = useFormikContext<FormikValues>(); // Formik context
  const [field, meta] = useField(name); // useField hook to get field props and metadata

  const [value, setValue] = useState<any>(_value || field?.value || '');

  useEffect(() => {
    if (disallow && field && disallow.test(field.value)) {
      setFieldValue(name, field?.value.replace(disallow, ''));
      setValue(field?.value.replace(disallow, ''));
    } else {
      setValue(field?.value || '');
    }
  }, [disallow, field, name, setFieldValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (name && field && setFieldValue) {
      setValue(event.target.value);
      setFieldValue(name, event.target.value);
    }
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (name && field && setFieldValue) {
      setFieldValue(name, event.target.value);
      setValue(event.target.value);
      console.log('event.target.value:', event.target.value);
    }
  };
  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (name && field && setFieldValue) {
      setValue(event.target.value);
      setFieldValue(name, event.target.value);
    }
  };

  useEffect(() => {
    if (name && field && setFieldValue && _value !== undefined) {
      setValue(value);
      setFieldValue(name, _value);
    }
    //eslint-disable-next-line
  }, [_value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    switch (type) {
      case 'select':
        _onChange && _onChange(event as ChangeEvent<HTMLSelectElement>);
        handleSelectChange(event as ChangeEvent<HTMLSelectElement>);
        break;
      case 'textarea':
        _onChange && _onChange(event as ChangeEvent<HTMLTextAreaElement>);
        handleTextareaChange(event as ChangeEvent<HTMLTextAreaElement>);
        break;
      default:
        _onChange && _onChange(event as ChangeEvent<HTMLInputElement>);
        handleInputChange(event as ChangeEvent<HTMLInputElement>);
        break;
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    validateField(name);
    switch (type) {
      case 'select':
        onBlur && onBlur(event as FocusEvent<HTMLSelectElement>);
        break;
      case 'textarea':
        onBlur && onBlur(event as FocusEvent<HTMLTextAreaElement>);
        break;
      default:
        onBlur && onBlur(event as FocusEvent<HTMLInputElement>);
        break;
    }
  };

  const getProps = () => {
    switch (type) {
      case 'select':
        return {
          ...restProps,
          value: value, // set the value from Formik state
          onChange: handleChange,
          onBlur: handleBlur,
        } as FormFieldSelectProps;
      case 'textarea':
        return {
          ...restProps,
          value: value, // set the value from Formik state
          onChange: handleChange,
          onBlur: handleBlur,
        } as FormFieldTextareaProps;
      default:
        return {
          ...restProps,
          value: value, // set the value from Formik state
          onChange: handleChange,
          onBlur: handleBlur,
        } as FormFieldInputProps;
    }
  };

  const inputProps = getProps();

  const error = _error || (meta?.touched && meta?.error);

  return (
    <div className={classNames(style.container, { [style.large]: fieldSize === 'large' })}>
      {label && (
        <label className={style.label} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={style.inputWrapper}>
        {type === 'select' ? (
          <select
            {...(inputProps as FormFieldSelectProps)}
            className={classNames(style.field, {
              [style.invalid]: !!error,
              [style.inputAfter]: !!afterinput,
              [style.disabled]: props.disabled,
              [style.large]: fieldSize === 'large',
            })}
          >
            <option style={{ display: 'none' }}>{inputProps.placeholder}</option>
            {children}
          </select>
        ) : type === 'textarea' ? (
          <textarea
            {...(inputProps as FormFieldTextareaProps)}
            className={classNames(
              style.field,
              {
                [style.invalid]: !!error,
                [style.inputAfter]: !!afterinput,
                [style.disabled]: props.disabled,
                [style.large]: fieldSize === 'large',
              },
              className
            )}
          >
            {children}
          </textarea>
        ) : (
          <input
            {...(inputProps as FormFieldInputProps)}
            className={classNames(
              style.field,
              {
                [style.invalid]: !!error,
                [style.inputAfter]: !!afterinput,
                [style.disabled]: props.disabled,
                [style.large]: fieldSize === 'large',
              },
              className
            )}
            id={id}
          />
        )}
        {afterinput && <span className={style.inputAfterText}>{afterinput}</span>}
        {endAdornment && <KeyboardArrowDownIcon aria-hidden className={style.endAdornmentIcon} />}
      </div>
      {error && (
        <div className={style.error}>
          <Error className={style.svgError} />
          {error}
        </div>
      )}
      {helpertext && <div className={style.helpertext}>{helpertext}</div>}
    </div>
  );
};

export { FormField };
