import { Card } from 'components/card/card.component';
import { FormField } from 'components/forms';
import { Column, Grid } from 'components/grid/grid.component';

export const AccountInfoPrivate = () => {
  return (
    <Card>
      <h2 className="heading-small text-blue-dark">Grundinformation</h2>

      <FormField
        fieldSize="large"
        type="text"
        disallow={/[^0-9-]/}
        id="personalNumber"
        name="personalNumber"
        label="Personnummer"
        required
        placeholder="123456-1234"
      />

      <FormField
        fieldSize="large"
        type="text"
        id="adress"
        name="adress"
        label="Adress"
        required
        placeholder="Gatuvägen 1"
      />
      <Grid formgrid>
        <Column span={6}>
          <FormField
            fieldSize="large"
            disallow={/[^0-9\ ]/}
            type="text"
            id="postCode"
            name="postCode"
            label="Postnummer"
            required
            placeholder="123 45"
          />
        </Column>
        <Column span={6}>
          <FormField
            fieldSize="large"
            type="text"
            id="city"
            name="city"
            label="Postadress"
            required
            placeholder="Ortsnamn"
          />
        </Column>
      </Grid>
    </Card>
  );
};
