import classNames from 'classnames';
import { HTMLProps } from 'react';
import './card.module.scss';

interface CardProps extends HTMLProps<HTMLDivElement> {
  flexDirection?: 'row' | 'column';
}

export const Card = (props: CardProps) => {
  const { className, children, flexDirection = 'column', ...rest } = props;

  return (
    <div className={classNames(className, 'card', flexDirection)} {...rest}>
      {children}
    </div>
  );
};
