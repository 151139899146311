import React, { ReactNode } from 'react';
import style from './white-background.module.scss';

type WhiteBackgroundProps = {
  children: ReactNode;
};

export const WhiteBackground: React.FC<WhiteBackgroundProps> = ({ children }) => (
  <div className={style.background}>{children}</div>
);
