import classNames from 'classnames';
import { HTMLAttributes, useId, useState } from 'react';
import style from './accordion.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  onToggle?: (open: boolean) => void;
  label: string;
  size?: 'standard' | 'large';
}

export const Accordion: React.FC<AccordionProps> = (props) => {
  const { className, children, label, open: _open = false, id: _id, onToggle, size = 'standard', ...rest } = props;
  const [open, setOpen] = useState<boolean>(_open);

  const randomId = useId();

  const id = _id || randomId;

  const handleClick = () => {
    onToggle && onToggle(!open);
    setOpen(!open);
  };

  return (
    <div className={classNames(className, style.wrapper, size === 'large' && style.large)} {...rest} id={id}>
      <div
        className={style.header}
        role="button"
        onClick={() => handleClick()}
        aria-expanded={open}
        aria-controls={`${id}-content`}
      >
        <label id={`${id}-label`}>{label}</label>

        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      {open && (
        <div className={style.content} aria-labelledby={`${id}-label`} role="region" id={`${id}-content`}>
          {children}
        </div>
      )}
    </div>
  );
};
