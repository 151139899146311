import React, { ReactNode } from 'react';
import style from './summer-background.module.scss';

type SummerBackgroundProps = {
  children: ReactNode;
};

const SummerBackground: React.FC<SummerBackgroundProps> = ({ children }) => (
  <div className={style.background}>{children}</div>
);

export default SummerBackground;
