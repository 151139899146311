import Personnummer from 'personnummer';
import * as Yup from 'yup';
import { Card } from 'components/card/card.component';
import { FormField } from 'components/forms/form-field';
import { useLocations } from 'services/locations.service';
import { useUserStore } from 'stores';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { AccountType } from 'constants/account-type.enum';
import { AccountInfoBusiness } from './components/account-info-business.component';
import { AccountInfoPrivate } from './components/account-info-private.component';
import { validOrgNumberPattern } from 'constants/regex';
import { Column, Grid } from 'components/grid/grid.component';

const AccountInfo = () => {
  const { setFieldValue, values } = useFormikContext<{
    answerEmail: string;
    personalNumber: string;
    adress: string;
    postCode: string;
    city: string;
    placeId: number;
    type: AccountType;
  }>();

  const { data: locations } = useLocations(true);
  const { user } = useUserStore();

  useEffect(() => {
    if (user) {
      setFieldValue('city', user?.city || '', false);
      setFieldValue('answerEmail', user?.answerEmail || user.email, false);
      setFieldValue('adress', user?.adress || '', false);
      setFieldValue('postCode', user?.postCode || '', false);
      setFieldValue('placeId', user?.placeId || '', false);
      if (values.type === AccountType.Private) {
        setFieldValue('personalNumber', user?.personalNumber || '', false);
      }
      if (values.type === AccountType.Company) {
        setFieldValue('orgNumber', user?.orgNumber || user?.personalNumber || '', false);
        setFieldValue('companyName', user?.companyName || '', false);
      }
    }
    //eslint-disable-next-line
  }, [setFieldValue, user]);

  return (
    <Grid>
      <Column span={[8, 8, 12, 4]}>
        {values.type === AccountType.Company ? <AccountInfoBusiness /> : <AccountInfoPrivate />}
      </Column>
      <Column span={[4, 4, 12, 4]}>
        <Card>
          <h2 className="heading-small text-blue-dark">Försäljningsdetaljer</h2>
          <div>
            <FormField
              fieldSize="large"
              type="select"
              id="placeId"
              name="placeId"
              label="Plats*"
              placeholder="Välj plats..."
              helpertext="Ange den plats du oftast utgår ifrån. Om du säljer från olika platser, kan du välja det när du skapar en
              annons."
            >
              {locations.map((location, index) => (
                <option key={`loc-${location.id}-${index}`} value={location.id}>
                  {location.label}
                </option>
              ))}
            </FormField>
          </div>

          <hr />

          <FormField
            fieldSize="large"
            type="text"
            id="answerEmail"
            name="answerEmail"
            label="Svarsadress*"
            required
            placeholder="kontakt@kontakt.se"
            helpertext="Den e-post du vill få dina svar på annonser till.Denna information är publik."
          />
        </Card>
      </Column>
    </Grid>
  );
};

AccountInfo.title = 'Komplettera dina kontouppgifter';

AccountInfo.initialValues = {
  companyName: '',
  orgNumber: '',
  personalNumber: '',
  adress: '',
  postCode: '',
  city: '',
  placeId: null,
  answerEmail: '',
  tax: false,
};
AccountInfo.validationSchema = Yup.object().shape({
  personalNumber: Yup.string().when('type', (type: any[], schema) => {
    if (type[0] === AccountType.Private) {
      return schema
        .test(
          'is-valid-pnr',
          (d) => `Ogiltigt personnummer`,
          (value) => Personnummer.valid(value ?? '')
        )
        .required('Personnummer krävs');
    }
    return schema;
  }),

  orgNumber: Yup.string().when('type', (type: any, schema) => {
    if (type[0] === AccountType.Company) {
      return schema
        .test('is-valid-pnr', `Ogiltigt organisationsnummer`, (value) => validOrgNumberPattern.test(value ?? ''))
        .required('Organisationsnummber krävs');
    }
    return schema;
  }),
  companyName: Yup.string().when('type', (type: any, schema) => {
    if (type[0] === AccountType.Company) {
      return schema.required('Bolagsnamn krävs');
    }
    return schema;
  }),
  adress: Yup.string().required('Adress krävs'),
  postCode: Yup.number()
    .min(10000, 'Ogiltigt postnummer')
    .max(99999, 'Ogiltigt postnummer')
    .required('Postnummer krävs'),
  city: Yup.string().required('Ortsnamn krävs'),
  placeId: Yup.number().required('Plats krävs'),
  answerEmail: Yup.string().email('Ogiltig e-post adress').required('Svarsadress krävs'),
  tax: Yup.bool().when('type', (type: any, schema) => {
    if (type[0] === AccountType.Company) {
      return schema.required('Måste inneha en F-skattesedel').isTrue('Måste inneha en F-skattesedel');
    }
    return schema;
  }),
});

export { AccountInfo };
