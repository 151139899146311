import { ErrorMessage, Field, FieldProps } from 'formik';
import * as Yup from 'yup';
import { Card } from 'components/card/card.component';
import { Checkbox } from 'components/forms/checkbox/checkbox.component';
import styleFromFormField from 'components/forms/form-field/form-field.module.scss';
import { FormField } from 'components/forms';

const Conditions = () => {
  return (
    <Card>
      <h2 className="heading-small text-blue-dark">Våra villkor</h2>
      <FormField
        fieldSize="large"
        type="textarea"
        id="ourconditions"
        name="ourconditions"
        placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu libero ligula. Aenean hendrerit tellus quis est ullamcorper accumsan. Vestibulum sagittis mollis lorem at sagittis. Cras eu elit id dolor ultricies consequat a sed libero. Vivamus sodales augue at tellus vestibulum, sit amet aliquam quam euismod. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque vel hendrerit enim, vitae sodales urna. Morbi imperdiet tortor dui, ut dignissim purus mattis nec. Aliquam eget est lorem. Nunc sed felis sagittis, volutpat lectus varius, sollicitudin nibh. Maecenas pretium turpis sapien, sed pulvinar odio faucibus pharetra. Phasellus dapibus iaculis facilisis. Nam vitae odio a augue dignissim vulputate non sit amet quam. Suspendisse cursus ex ornare purus vulputate venenatis.
							Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam a ipsum quis dolor feugiat tempus ac volutpat massa. Vivamus eget pulvinar nisl. Proin diam purus, porttitor ac neque vitae, varius lacinia massa. Curabitur neque orci, porttitor a euismod at, molestie eu tellus. Aliquam erat volutpat. Sed eu pulvinar leo, vel hendrerit leo. Ut enim ligula, viverra quis rutrum at, cursus eget mi. Maecenas mollis elit a velit tincidunt, eu accumsan leo interdum. Donec dictum ac arcu eget faucibus. Proin fermentum urna enim, eu varius ipsum volutpat ac.
							Fusce placerat metus sed aliquam hendrerit. Integer pharetra laoreet vehicula. Sed a leo sed augue varius ultricies. Morbi vel mauris sapien. Nulla malesuada eget sapien eget lacinia. Curabitur varius malesuada enim eget congue. Integer ultricies dignissim ultrices."
        readOnly
        rows={14}
      />
      <div>
        <Field name="privacy">
          {({ field }: FieldProps) => (
            <Checkbox
              className="large"
              label="Jag godkänner villkoren"
              {...field}
              id="privacy"
              checked={field.value ? true : false}
            />
          )}
        </Field>

        <ErrorMessage name="privacy">
          {(errorMessage: string) => (
            <div className={styleFromFormField.error}>
              <img src="/static/media/icon-error.9793803858ddef6d644ee645640bde7e.svg" alt="" />
              {errorMessage}
            </div>
          )}
        </ErrorMessage>
      </div>
    </Card>
  );
};
Conditions.title = 'Godkänn villkor';
Conditions.initialValues = {
  privacy: false,
};
Conditions.validationSchema = Yup.object({
  privacy: Yup.bool().required('Måste godkänna villkoren').isTrue().oneOf([true], 'Måste godkänna villkoren'),
});
export default Conditions;
