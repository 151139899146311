import React, { ReactNode } from 'react';
import style from './feathers-background.module.scss';

type FeathersBackgroundProps = {
  children: ReactNode;
};

export const FeathersBackground: React.FC<FeathersBackgroundProps> = ({ children }) => (
  <>
    <div className={style.background}>{children}</div>
  </>
);
