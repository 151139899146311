import { AdPage } from 'components/ad-page/ad-page.component';
import { Spinner } from 'components/spinner/spinner.component';
import { AdType } from 'constants/ad-type.enum';
import { PriceType } from 'constants/price-type.enum';
import { useFormikContext } from 'formik';
import { Category } from 'interfaces/category.interface';
import { HTMLProps } from 'react';
import { useCategories } from 'services/categories.service';
import { useLocations } from 'services/locations.service';
import { useUserStore } from 'stores';
import { getFileUrl } from 'utils/get-local-url.util';
import * as Yup from 'yup';

interface AdConfirmProps extends HTMLProps<HTMLDivElement> {
  fixedPrice: number;
  hourlyPrice: number;
  priceType: PriceType;
  otherCosts: string;
  travelCost: number;
  description: string;
  headline: string;
  placeId: string;
  images: File[];
  categories: number[];
  otherCostsCheckbox: boolean;
  travelCostCheckbox: boolean;
}

const AdConfirm = () => {
  const { data: cats, loaded: catsLoaded } = useCategories();
  const { data: locs, loaded: locsLoaded } = useLocations();
  const { values } = useFormikContext<AdConfirmProps>();
  const user = useUserStore((state) => state.user);

  const today = new Date();
  return !user || !catsLoaded || !locsLoaded ? (
    <Spinner />
  ) : (
    <AdPage
      data={{
        ...values,
        categories: values.categories
          .map((catId) => cats.find((cat) => cat.id === catId))
          .filter((cat) => cat !== undefined) as Category[],
        images: values.images.map((file) => ({ name: file.name, filename: file.name, fullUrl: getFileUrl(file) })),
        placeId: parseInt(values.placeId, 10),
        place: locs.find((loc) => loc.id === parseInt(values.placeId, 10)),
        type: values.type as AdType,
        id: 0,
        user: user,
        userId: user.id,
        createdAt: today.toDateString(),
        updatedAt: today.toDateString(),
      }}
      preview
    />
  );
};

AdConfirm.title = 'Granska och godkänn';
AdConfirm.label = 'Granska';

AdConfirm.initialValues = {};

AdConfirm.validationSchema = Yup.object().shape({});

export default AdConfirm;
