import axios from 'axios';
import { useUserStore } from '../stores';

const baseURL = `${process.env.REACT_APP_BACKEND_URL}`;

export interface Data {
  error?: string;
}

export interface ApiReturnResponse<T> {
  data: T;
  error: boolean | string;
}

export interface ApiResponse<T> {
  data: T;
  message: string;
}

const handleError = (error: any) => {
  const tokens = useUserStore.getState().tokens;
  const clearUser = useUserStore.getState().clear;
  const setTokens = useUserStore.getState().setTokens;

  if (tokens) {
    if (error.response.status === 401) {
      axios
        .post<any>(`${baseURL}/auth/refresh-token`, { refreshToken: tokens.refreshToken }, { ...defaultOptions })
        .then((res: any) => {
          if (res?.data) {
            setTokens(res?.data);
          }
        })
        .catch(() => {
          clearUser();
        });
    } else {
      clearUser();
    }
  }

  console.log(error.message);
};

const defaultOptions = () => {
  const tokens = useUserStore.getState().tokens;
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokens?.accessToken}`,
      'Access-Control-Allow-Origin': '*',
    },
    withCredentials: true,
  };
};

const withRefresh = async (callFunc: any) => {
  const clearUser = useUserStore.getState().clear;
  const setTokens = useUserStore.getState().setTokens;
  const tokens = useUserStore.getState().tokens;

  if (tokens) {
    const now = new Date();
    const expires = new Date(tokens.expires);
    if (now >= expires) {
      try {
        const newTokens = await axios
          .post<any>(`${baseURL}/auth/refresh-token`, { refreshToken: tokens.refreshToken }, { ...defaultOptions })
          .then((res: any) => res?.data);

        if (newTokens) {
          setTokens(newTokens);
        }
      } catch (e) {
        clearUser();
      }
    }
  }
  return callFunc;
};

const get = <T>(url: string, options?: { [key: string]: any }) =>
  withRefresh(
    axios
      .get<T>(`${baseURL}/${url}`, {
        ...defaultOptions(),
        ...options,
        headers: { ...defaultOptions().headers, ...options?.headers },
      })
      .catch(handleError)
  );

const post = <T>(url: string, data: any, options?: { [key: string]: any }) =>
  withRefresh(
    axios
      .post<T>(`${baseURL}/${url}`, data, {
        ...defaultOptions(),
        ...options,
        headers: { ...defaultOptions().headers, ...options?.headers },
      })
      .catch(handleError)
  );

const remove = <T>(url: string) =>
  withRefresh(axios.delete<T>(`${baseURL}/${url}`, defaultOptions()).catch(handleError));

const patch = <T>(url: string, data: any, options?: { [key: string]: any }) =>
  withRefresh(
    axios
      .patch<T>(`${baseURL}/${url}`, data, {
        ...defaultOptions(),
        ...options,
        headers: { ...defaultOptions().headers, ...options?.headers },
      })
      .catch(handleError)
  );

export const apiService = { get, post, patch, delete: remove };
