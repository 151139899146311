import { SearchBar } from 'components/searchbar/searchbar.component';
import { AdType } from 'constants/ad-type.enum';
import { FormEvent, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { useAdsStore } from 'services/ads.service';
import { useUserStore } from 'stores';
import style from './search-modal.module.scss';

interface SearchModalProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  open: boolean;
  query: string;
  onChange: (query: string) => void;
  onSubmit: (event: FormEvent) => void;
  onClose: () => void;
}

export const SearchModal: React.FC<SearchModalProps> = ({ open, query, onClose, onChange, onSubmit, ...rest }) => {
  const user = useUserStore((state) => state.user);
  const [setFilter, setSearchstring] = useAdsStore((state) => [state.setFilter, state.setSearchstring]);

  return !open ? (
    <></>
  ) : (
    <div className={style.wrapper} {...rest} onKeyDown={(event) => event.key === 'Escape' && onClose()}>
      <div className={style.modal}>
        <form onSubmit={onSubmit} className={style.form}>
          <SearchBar value={query} onChange={onChange} />
        </form>
        <div className={style.menu} role="menubar">
          <Link
            role="menuitem"
            to="/search"
            onClick={() => {
              setSearchstring('');
              setFilter({ type: AdType.Service, placeId: user?.placeId ? [user.placeId, 0] : undefined });
              onClose();
            }}
          >
            Tjänster
          </Link>
          <Link
            role="menuitem"
            to="/search"
            onClick={() => {
              setSearchstring('');
              setFilter({ type: AdType.Request, placeId: user?.placeId ? [user.placeId, 0] : undefined });
              onClose();
            }}
          >
            Förfrågningar
          </Link>
        </div>
      </div>
    </div>
  );
};
