import { Navigate } from 'react-router-dom';
import { useUserStore } from '../../stores';
import { ReactNode } from 'react';

interface AuthProps {
  children: ReactNode;
}
const Auth: React.FC<AuthProps> = ({ children }) => {
  const user = useUserStore((state) => state.user);

  return user ? <>{children}</> : <Navigate to="/" />;
};

export default Auth;
